<template>
    <master-panel no-full-body>
        <template v-slot:body>
            <loading-spinner v-if="loading" line height=""/>
            <v-row v-else-if="client">
                <v-col cols="12" class="text-center">
                    <v-avatar color="#cccccc">
                        <v-icon size="30" color="#999999" v-text="'$user'"/>
                    </v-avatar>
                    <br>
                    <div class="client-name">
                        {{ client.name }}
                    </div>
                </v-col>
                <v-col cols="12" class="mt-3">
                    <v-row class="info-container">
                        <v-col cols="2" md="1" class="bottom-border primary" align="center">
                            <v-icon color="#FFFFFF" v-text="'$email'"/>
                        </v-col>
                        <v-col cols="10" md="11" class="bottom-border">
                            {{ client.email }}
                        </v-col>
                        <v-col cols="2" md="1" class="bottom-border ternary" align="center">
                            <v-icon color="#999999" v-text="'$phone'"/>
                        </v-col>
                        <v-col cols="10" md="11" class="bottom-border">
                            {{ client.phone }}
                        </v-col>
                        <v-col cols="2" md="1" class="primary" align="center">
                            <v-icon color="#FFFFFF" v-text="'mdi-map-marker'"/>
                        </v-col>
                        <v-col cols="10" md="11">
                            {{ client.region }} - {{ client.city }}
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <div v-if="confirmed" class="response-container">
                        Paciente confirmado
                        <v-icon size="17" color="#FFFFFF" class="mb-1" v-text="'$check_circle'"/>
                    </div>
                    <v-btn
                        v-else block
                        color="primary"
                        large depressed
                        class="normal-btn"
                        @click="confirmClient"
                        :loading="loadingConfirm"
                    >
                        Confirmar paciente
                    </v-btn>
                </v-col>
            </v-row>
        </template>
        <template v-slot:buttons>
            <v-row dense>
                <v-col cols="6">
                    <v-btn color="primary" small depressed class="normal-btn" @click="goBack">
                        Volver
                    </v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn color="primary" small depressed class="normal-btn" @click="goHome">
                        Dashboard
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </master-panel>
</template>

<script>
import MasterPanel from "@/components/layouts/MasterPanel";
import LoadingSpinner from "@/components/common/LoadingSpinner";
import httpService from "@/services/httpService";

export default {
    name: "ClientDetails",
    components: {LoadingSpinner, MasterPanel},
    data() {
        return {
            loading: false,
            client: null,
            loadingConfirm: false,
        }
    },
    computed: {
        clientId(vm = this) {
            return vm.$route.params.id || null
        },
        confirmed(vm = this) {
            return vm.client?.confirmed_hour?.confirmed || false
        },
    },
    async mounted() {
        const vm = this
        await vm.loadClient()
    },
    methods: {
        async loadClient() {
            const vm = this
            vm.loading = true
            let response = await httpService.get(`client/${vm.clientId}`)
            // console.log('loadClient', response)
            vm.client = response.data.client
            vm.loading = false
        },
        async confirmClient() {
            const vm = this
            vm.loadingConfirm = true
            let response = await httpService.post(`confirmed_hours/${vm.client.confirmed_hour.id}/confirm`)
            // console.log('confirmClient', response)
            vm.client.confirmed_hour.confirmed = 1
            vm.loadingConfirm = false
        }
    },
}
</script>

<style scoped>

.client-name {
    margin-top: 12px;
    text-align: center;
    background-color: var(--v-secondary-base);
    padding: 8px 22px;
    border-radius: 12px;
    color: #FFFFFF;
}

.info-container {
    overflow: hidden;
    border-radius: 12px;
    border: 1px solid #cccccc;
    margin: 0;
}

.bottom-border {
    border-bottom: 1px solid #cccccc;
}

.response-container {
    text-align: center;
    padding: 4px 22px;
    border-radius: 12px;
    background-color: var(--v-secondary-base);
    color: #FFFFFF;
}

</style>